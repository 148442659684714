@import '~@alaskaairux/design-tokens/dist/tokens/SCSSVariables';

.home{

	height: 72px;
    position: relative;
    margin: 24px 24px 24px 24px;
    align-content: middle;
    min-width: 976px;
}

.link-style-body {
  color: #336787;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    margin-left: 28px;
    font-weight: 500;
    line-height: 1.2;
    text-decoration: none;
}
  
.label-sm {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  top: 34px;
}
